import { Box, Divider, Grid, Heading, Text } from '@chakra-ui/react';
import { NextSeo } from 'next-seo';
import React from 'react';

import { Footer } from '../components/organisms/footer';
import { Header } from '../components/organisms/header';
import { ProductCard } from '../components/organisms/product-card';

interface Props {
  avgReservationsLastMonth: number;
}

const Index: React.FC<Props> = () => {
  return (
    <Box>
      <NextSeo title="Viresmo" />

      <Box minHeight="100vh">
        <Header />

        <Box as="main" maxW="7xl" px="8" mt="32" mb="64" mx="auto">
          <Heading size="2xl" letterSpacing="tight" mb="5" fontWeight="extrabold" color="brand.300">
            Ontdek onze Producten
          </Heading>

          <Divider my="10" />

          <Grid templateColumns="repeat(2, 1fr)" gap={6} mt="16">
            <ProductCard href="https://www.deversbox.be/" name="De Versbox" image="/assets/logo_versbox.svg">
              <Text>
                Een afhaalsysteem dat gekoppeld kan worden aan een webshop, de woyti app of zelfs volledig onafhankelijk
                gebruikt kan worden.
              </Text>
              <Text mt="4">
                De klant kan hierdoor een bestelling 24/7 afhalen door een door hen gekozen afhaalmoment.
              </Text>
            </ProductCard>
            <ProductCard href="https://woyti.eu/" name="Woyti" image="/assets/logo_woyti.svg">
              <Text>
                Als handelaar kies je zelf welke producten je op een bepaald moment aanbied, klanten kunnen dan kiezen
                via de woyti app wat ze willen kopen uit jouw automaat.
              </Text>
            </ProductCard>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default Index;
